import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import tw, { styled } from 'twin.macro';

import Layout from '../components/Layout';
import { Locales } from '../types/QueryTypes';

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { eq: "common" }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: "/privacy-policy" } } }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            langKey
            slug
          }
        }
      }
    }
  }
`;

interface DataProps {
  locales: Locales;
  allMarkdownRemark: {
    edges: [
      {
        node: {
          id: string;
          html: string;
          frontmatter: {
            langKey: string;
            slug: string;
          };
        };
      }
    ];
  };
}

const Container = styled.div`
  ${tw`text-gray-600`}

  h1 {
    ${tw`pb-2 font-bold text-lg`}
  }

  p {
    ${tw`pb-2 text-sm`}
  }

  ul {
    ${tw`pb-2 text-sm list-disc list-inside`}
  }

  li {
    ${tw`text-sm`}
  }

  h3 {
    ${tw`pt-4 pb-2 font-bold text-sm`}
  }
`;

function PrivacyPolicy({ data }: PageProps<DataProps>) {
  const { html } = data.allMarkdownRemark.edges[0].node;

  return (
    <Layout>
      <Container
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </Layout>
  );
}

export default PrivacyPolicy;
